import {
	Alert,
	Box,
	Grid,
	Hidden,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import {navigate} from 'gatsby';
import React, {Fragment, useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../../actions';
import Paginator from '../../Paginator';
import BaseLoader from '../../utils/BaseLoader';
import formatAmount from "../../utils/formatAmount";
import OrderStatusChip from '../../utils/OrderStatusChip';
import HeaderTableCell from '../../utils/StyledTableCell';
import TagChip from "../../utils/TagChip";
import OrdersFilters from "./ordersFilters";

const mapStateToProps = state => ({
	orders: state.getOrders,
	tags: state.getTags,
});

const PREFIX = "OrdersList";
const classes = {
	root: `${PREFIX}-root`,
	tableCell: `${PREFIX}-tableCell`,
	cardContent: `${PREFIX}-cardContent`,
	gridItem: `${PREFIX}-gridItem`,
	upper: `${PREFIX}-upper`,
	descColumn: `${PREFIX}-descColumn`,
};

const RootPaginator = styled(Paginator)(({theme}) => ({
	[`& .${classes.root}`]: {
		'& > *': {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(4),
		},
	},
	[`& .${classes.tableCell}`]: {
		[theme.breakpoints.down('sm')]: {
			padding: '16px 8px',
		},
	},
	[`& .${classes.cardContent}`]: {
		paddingBottom: 0,
	},
	[`& .${classes.gridItem}`]: {
		borderTop: '1px solid #e3e3e3',
	},
	[`& .${classes.upper}`]: {
		textTransform: 'uppercase',
	},
	[`& .${classes.descColumn}`]: {
		maxWidth: '250px',
		wordWrap: 'break-word',
	},
}));

const BodyTableCell = styled(TableCell)(({theme}) => ({
	padding: '12px',
}));

const OrderRow = ({order}) => {
	const {code, amount, currency, description, createdAt, status} = order.data;

	return (
		<Fragment key={code}>
			<TableRow
				hover
				key={code}
				onClick={() => navigate(`/orders/${code}`)}
				className="row"
			>
				<BodyTableCell>{code}</BodyTableCell>
				<BodyTableCell sx={{whiteSpace: 'nowrap'}}>{createdAt}</BodyTableCell>
				<BodyTableCell className={classes.descColumn}>
					<Box>
						{order.debtor?.fullNameAndEmail || (
							<i style={{opacity: 0.6}}>nie wskazano</i>
						)}
					</Box>
				</BodyTableCell>
				<BodyTableCell className={classes.descColumn}>
					<Box>{description || <i style={{opacity: 0.6}}>brak opisu</i>}</Box>
				</BodyTableCell>
				<BodyTableCell align="right">
					{parseFloat(amount).toLocaleString('pl-PL', {
						minimumFractionDigits: 2,
					})}{' '}
					{currency}
				</BodyTableCell>
				<BodyTableCell align="center">
					<OrderStatusChip status={status}/>
				</BodyTableCell>
			</TableRow>
		</Fragment>
	);
};

export const OrdersListTable = ({orders, children}) => (
	<TableContainer>
		<Paper sx={{mt: 2}}>
			<Table>
				<TableHead className={classes.test}>
					<TableRow>
						<HeaderTableCell className={classes.tableCell}>
							Kod zlecenia
						</HeaderTableCell>
						<HeaderTableCell className={classes.tableCell}>
							Utworzono
						</HeaderTableCell>
						<HeaderTableCell className={classes.tableCell}>
							Płatnik
						</HeaderTableCell>
						<HeaderTableCell className={classes.tableCell}>
							Opis
						</HeaderTableCell>
						<HeaderTableCell align="right" className={classes.tableCell}>
							Kwota
						</HeaderTableCell>
						<HeaderTableCell align="center" className={classes.tableCell}>
							Status
						</HeaderTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{orders.map(order => (
						<OrderRow key={order.data.code} order={order}/>
					))}
				</TableBody>
			</Table>
		</Paper>
		<div className={classes.root}>
			<Grid container justifyContent="center" alignItems="center" py={2}>
				<Grid item>{children}</Grid>
			</Grid>
		</div>
	</TableContainer>
);

const OrdersAmountSummary = ({summary}) => (
	<Paper>
		<Typography variant="h6" sx={{px: 2, pt: 2}}>
			Podsumowanie
		</Typography>
		<Table>
			<TableHead>
				<TableRow>
					{[
						'Ilość',
						'Suma',
						'Opłacone',
						'Nieopłacone',
						'Zaległe',
					].map(col => <TableCell>{col}</TableCell>)}
				</TableRow>
			</TableHead>
			<TableBody>
				<TableCell sx={{whiteSpace: 'nowrap'}}>{summary.count}</TableCell>
				{[summary.totalAmount,
					summary.payedAmount,
					summary.openAmount,
					summary.overdueAmount].map(value => (
					<TableCell>
						{formatAmount(value, 'PLN')}
					</TableCell>
				))}
			</TableBody>
		</Table>
	</Paper>
)

export const OrdersListCards = ({orders, children}) => (
	<>
		{orders.map(({data, tags, debtor}) => (
			<Fragment key={data.code}>
				<Paper
					onClick={() => navigate(`/orders/${data.code}`)}
					variant="outlined"
					style={{cursor: 'pointer'}}
				>
					<Box p={2}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Box textAlign={'right'} className={'amount'}>
									{data.amount} {data.currency}
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Box textAlign={'right'}>
									<OrderStatusChip status={data.status}/>
								</Box>
							</Grid>
							<Grid
								item
								xs={6}
								className={`${classes.gridItem} ${classes.upper}`}
							>
								<Box textAlign={'left'}>Kod zlecenia</Box>
							</Grid>
							<Grid item xs={6} className={classes.gridItem}>
								<Box textAlign={'right'}>{data.code}</Box>
							</Grid>
							<Grid
								item
								xs={6}
								className={`${classes.gridItem} ${classes.upper}`}
							>
								<Box textAlign={'left'}>Opis:</Box>
							</Grid>
							<Grid
								item
								xs={6}
								className={classes.gridItem}
								style={{wordWrap: 'break-word'}}
							>
								<Box textAlign={'right'}>
									{data.description || (
										<i style={{opacity: 0.75}}>brak opisu</i>
									)}
								</Box>
							</Grid>
							<Grid
								item
								xs={6}
								className={`${classes.gridItem} ${classes.upper}`}
							>
								<Box textAlign={'left'}>Płatnik:</Box>
							</Grid>
							<Grid
								item
								xs={6}
								className={classes.gridItem}
								style={{wordWrap: 'break-word'}}
							>
								<Box textAlign={'right'}>
									{debtor?.fullNameAndEmail || (
										<i style={{opacity: 0.75}}>brak opisu</i>
									)}
								</Box>
							</Grid>
							<Grid
								item
								xs={6}
								className={`${classes.gridItem} ${classes.upper}`}
							>
								<Box textAlign={'left'}>Utworzono:</Box>
							</Grid>
							<Grid item xs={6} className={classes.gridItem}>
								<Box textAlign={'right'}>{data.createdAt}</Box>
							</Grid>
							<Grid
								item
								xs={6}
								className={`${classes.gridItem} ${classes.upper}`}
							>
								<Box textAlign="left">Etykiety:</Box>
							</Grid>
							<Grid item xs={6}>
								<Box textAlign="right">
									{tags.map(({color, name}) => <TagChip
										key={name}
										label={name}
										bgCol={color}
									/>)}
								</Box>
							</Grid>
						</Grid>
					</Box>
				</Paper>
				<br/>
			</Fragment>
		))}
		<Grid container justifyContent="center" alignItems="center">
			<Grid item>{children}</Grid>
		</Grid>
	</>
);

const OrdersList = ({orders, actions, tags}) => {
	useEffect(() => actions.getAllTags({attachedOnly: true}), [actions]);

	const summary = orders?.summary;

	return (
		<OrdersFilters
			actions={actions}
			tags={tags}
			render={(params) => (
				<RootPaginator
					resource={orders}
					getResource={(page) => actions.getOrders({...page, ...params})}
					render={(orders, renderPagination, loading) => (
						<>
							<BaseLoader loading={loading}/>
							{!!orders?.length && (
								<>
									<Hidden mdDown>
										<OrdersListTable orders={orders}>
											{renderPagination()}
										</OrdersListTable>
									</Hidden>
									<Hidden mdUp>
										<OrdersListCards orders={orders}>
											{renderPagination()}
										</OrdersListCards>
									</Hidden>
									{summary && <OrdersAmountSummary summary={summary}/>}
								</>
							)}
							{orders && !orders.length && (
								<Alert severity="info">Brak zleceń dla wybranych parametrów wyszukiwania</Alert>
							)}
						</>
					)}
				/>
			)}
		/>
	);
}
const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersList);

import React, {useEffect, useState} from 'react';
import Layout from "../../templates/layout";
import BasePageContent from "../../components/utils/BasePageContent";
import {connect} from "react-redux";
import * as actions from '../../actions';
import {bindActionCreators} from "redux";
import {Alert, Box, Button, Grid, Hidden, Paper, Typography} from "@mui/material";
import Paginator from "../../components/Paginator";
import {OrdersListCards, OrdersListTable} from "../../components/user/orders/list";
import PayerDataDialog from "../../components/payers/PayerDataDialog";

const getPayerItems = (payerData) => ([
  {
    key: 'Imię',
    value: payerData.firstName || <i style={{color: 'lightgrey'}}>nie wskazano</i>,
  },
  {
    key: 'Nazwisko',
    value: payerData.lastName || <i style={{color: 'lightgrey'}}>nie wskazano</i>,
  },
  {
    key: 'Nazwa',
    value: payerData.name || <i style={{color: 'lightgrey'}}>nie wskazano</i>,
  },
  {
    key: 'Email',
    value: payerData.email || <i style={{color: 'lightgrey'}}>nie wskazano</i>,
  },
  {
    key: 'Numer telefonu',
    value: payerData.phoneNumber
      ? `${payerData.phonePrefix} ${payerData.phoneNumber}`
      : <i style={{color: 'lightgrey'}}>nie wskazano</i>,
  },
  {
    key: 'Utworzono',
    value: payerData.createdAt,
  },
  {
    key: 'Zaktualizowano',
    value: payerData.updatedAt,
  }
]);


const mapStateToProps = (state) => ({
  payer: state.findPayer,
  updatedPayer: state.updatePayer,
  orders: state.getOrdersByPayer,
});

const PayerDetails = ({code, payer, updatedPayer, orders, actions}) => {
  const [loading, setLoading] = useState(true);
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const openEditDialog = () => setEditDialogOpen(true);
  const closeEditDialog = () => {
    setEditDialogOpen(false);
  }

  useEffect(() => {
    if (payer?.data) {
      actions.getOrdersByPayer(payer.data.code);
    }
  }, [payer, actions])

  useEffect(() => {
    if (updatedPayer?.data) {
      closeEditDialog();
      actions.findPayer(code);
      setLoading(true);
    }
  }, [updatedPayer, actions, code])

  useEffect(() => {
    if (orders) setLoading(false);
  }, [orders])

  useEffect(() => actions.findPayer(code), [actions, code]);

  const payerData = payer?.data || updatedPayer?.data;

  return <Layout>
    <BasePageContent loading={loading}>
      {payerData && (
        <>
          <Paper sx={{height: '100%'}} variant='outlined'>
            <Box textAlign='center' sx={{
              background: (theme) => theme.palette.grey[100],
              color: '#000',
              fontWeight: 'bolder',
              p: 2,
            }}>
              <Typography variant='body1' sx={{
                fontWeight: 'bold',
              }}>
                Dane płatnika #{payerData.code}
              </Typography>
            </Box>
            <Box p={2}>
              <Grid container spacing={2} justifyContent='space-between'>
                {getPayerItems(payerData).map(({key, value}) => (
                  <Grid
                    key={key}
                    container
                    item
                    xs={12}
                    alignItems='center'
                    sx={{borderTop: '1px solid #e3e3e3'}}
                  >
                    <Grid item xs={5} sx={{textTransform: 'uppercase'}}>
                      <b>{key}</b>
                    </Grid>
                    <Grid item xs={6} sx={{wordWrap: 'break-word'}}>
                      <Box textAlign='right'>{value}</Box>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Grid item textAlign='center' xs={12}>
                <Button onClick={openEditDialog} variant='outlined'>
                  Edytuj
                </Button>
              </Grid>
            </Box>
          </Paper>
          <Grid container>
            <Grid item xs={12} my={4}>
              <Paginator
                resource={orders}
                getResource={pagination => actions.getOrdersByPayer(payerData.code, pagination)}
                render={(orders, renderPagination) => (
                  <>
                    {orders && (<>
                      <Box textAlign='center'>
                        <Typography variant='h5' paragraph>
                          Lista zleceń
                        </Typography>
                      </Box>
                      {orders.length ? (<>

                      <Hidden mdDown>
                        <OrdersListTable orders={orders}>
                          {renderPagination()}
                        </OrdersListTable>
                      </Hidden>
                      <Hidden mdUp>
                        <OrdersListCards orders={orders}>
                          {renderPagination()}
                        </OrdersListCards>
                      </Hidden>
                      </>) : <Alert severity='info'>Brak zleceń przypisanych do płatnika</Alert>
                      }
                    </>)}
                  </>
                )}
              />
            </Grid>
          </Grid>
        </>
      )}
      <PayerDataDialog
        payerData={payerData}
        errors={updatedPayer?.status === 'ERROR' ? updatedPayer.details : {}}
        dialogOpen={editDialogOpen}
        closeDialog={closeEditDialog}
        handleSubmit={(formData) => actions.updatePayer(payer.data.code, formData)}
      />
    </BasePageContent>
  </Layout>
}

export const Head = () => <title>Dane płatnika | Zapłatomat</title>;


const mapDispatchToProps = dispatch => ({actions: bindActionCreators(actions, dispatch)});

export default connect(mapStateToProps, mapDispatchToProps)(PayerDetails);
